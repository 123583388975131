<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 background justify-content-center"
      >
        <!-- <b-img
          fluid
          :src="imgUrl"
          class=""
          alt="Login V2"
        /> -->
        <h1 class="text-uppercase text-center text-light display-3">
          {{ $t('loginTitle') }}
        </h1>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div class="d-flex justify-content-center mb-3">
            <b-img
              :src="appLogoImage"
              alt="logo"
              width="150"
            />
          </div>
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            ¡Bienvenido! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            YoConstruyo
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>¿Olvidaste tu contraseña?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                class="mt-3 mb-2"
                block
                :disabled="invalid"
              >
                Iniciar sesión
              </b-button>
            </b-form>
          </validation-observer>
          <!-- <span
            class="text-primary cursor-pointer"
            block
            @click="$router.push({ name: 'register-type' })"
          >
            {{ $t('dontHaveAnAccount') }}
          </span> -->
          <span
            class="text-primary cursor-pointer"
            block
          >
            <a
              class="text-primary cursor-pointer"
              block
              target="_blank"
              href="https://wa.me/5218120166240?text=Me%20gustaría%20conocer%20mas%20de%20Yoconstruyo"
            >
              {{ $t('dontHaveAnAccount') }}
            </a>
          </span>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/login/login.jpg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/login/login.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.signIn({ email: this.userEmail, password: this.password })
            .then(response => {
              document.documentElement.style.setProperty(
                '--secondary-custom',
                response.secondary_color,
              )
              document.documentElement.style.setProperty(
                '--primary-custom',
                response.primary_color,
              )
              const userData = response
              useJwt.setToken(response.token)
              useJwt.setRefreshToken(response.refresh_token)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData))
                .then(() => {
                  // this.$router.go()
                })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-center',
                props: {
                  title: 'Error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.response.data.messages,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
.background {
  background-image: url('../../assets/images/login/login.jpg');
  background-size: cover;
  background-position: center center;
}
</style>
